<template>
  <b-card>
    <b-badge variant="primary" class="plan-tag">{{ label }}</b-badge>

    <div class="d-flex">
      <h1 class="text-primary">{{ currency || "$" }} {{ price }}</h1>
      <span class="mt-1">/ {{ frequency }}</span>
      <div class="ml-auto">
        

       <!--  <input type="radio" /> -->

        <div data-v-5063102f="" class="custom-control custom-switch">
          <input
            type="radio"
            :name="name"
            class="custom-control-input"
            :id="id"
            :value="value"
            @change="notifyChange"
           
          /><label class="custom-control-label" :for="id"></label>
        </div>
      </div>
    </div>
    <hr />
    <p class="text-center">
      10 Employées <br />
      Taches illimitées <br />
      2 Projets
    </p>
  </b-card>
</template>

<script>
export default {
  props: [
    "label",
    "price",
    "currency",
    "frequency",
    "checked",
    "options",
    "name",
    "id",
    "value",
  ],

  methods:{
    notifyChange(){
      this.$emit('switch',this.value)
    }
  }
};
</script>

<style scoped>
.plan-tag {
  margin-top: auto;
  position: relative;
  top: -30px;
  padding: 8px;
}
</style>