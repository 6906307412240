<template>
  <div>
    <b-jumbotron>
      <b-row>
        <b-col cols="auto">
          <h1>Choisisez un plan d'abonnement</h1>

          <p class="heading">Votre entreprise a bien été enregistré.</p>

          <p>Choisisez à présent le plan d'abonnement qui vous convient.</p>
        </b-col>

        <b-col>
          <ky-plan-card
            name="cc"
            :value="1"
            id="kpc2"
            :label="'Gratuit'"
            :price="10"
            :frequency="'mois'"
            @switch="(val) => (this.subs_plan_id = val)"
          />
        </b-col>
        <b-col>
          <ky-plan-card
            name="cc"
            :value="2"
            id="kpc1"
            :label="'Gratuit'"
            :price="10"
            :frequency="'mois'"
            @switch="(val) => (this.subs_plan_id = val)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="auto">
            <b-button>Enregistrer les informations</b-button>
        </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
import kyPlanCard from "@/@core/layouts/components/ky-plan-card.vue";
export default {
  components: { kyPlanCard },
  data() {
    return {
      subs_plan_id: 0,
    };
  },
  watch: {
    subs_plan_id(val) {
      alert(val);
    },
  },
};
</script>
<style scoped>
.plan-tag {
  margin-top: auto;
  position: relative;
  top: -30px;
  padding: 8px;
}
</style>